const getBracelets = async () =>{
    var requestOptions = {
        method: 'GET',
        redirect : 'follow'
    };
    const data= await fetch( process.env.REACT_APP_API_BASEURL + `/bracelets`, requestOptions)
    .then (response => response.json())
    .then (result =>{
        return result
    })
    .catch (error =>{ return error})
    return data
}

const getBraceletsInVersion = (version_id) =>{
    return new Promise((resolve, reject) =>{
        var requestOptions = {
            method: 'GET',
            redirect : 'follow'
        };
        fetch( process.env.REACT_APP_API_BASEURL + `/bracelets/version/${version_id}`, requestOptions)
        .then (response => response.json())
        .then (result =>{
            resolve (result)
        })
        .catch (error =>{ reject (error)})
        
    })
}

const getGamerByBraceletId = (bracelet_id ) =>{
    return new Promise ((resolve, reject) =>{
        var requestOptions = {
            method: 'GET',
            redirect : 'follow'
        };
        fetch( process.env.REACT_APP_API_BASEURL + `/gamer_bracelets/bracelet/${bracelet_id}`, requestOptions)
        .then (response => response.json())
        .then (result =>{
            resolve (result)
        })
        .catch (error =>{ reject (error)})
    })
}

const getBraceletsById = async (bracelet_id) =>{
    var requestOptions = {
        method: 'GET',
        redirect : 'follow'
    };
    const data= await fetch( process.env.REACT_APP_API_BASEURL + `/bracelets/${bracelet_id}`, requestOptions)
    .then (response => response.json())
    .then (result =>{
        return result
    })
    .catch (error =>{ return error})
    return data
}
const createBracelet = async (formData) =>{
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/bracelets`, requestOptions)  
    .then (response => response.json())
    .then (result =>{
        return result
    })
    .catch (error =>{ return error})
    return data
}

const deleteBracelet= async (braceletId ) =>{
    var requestOptions = {
        method: 'DELETE',
        redirect : 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/bracelets/${braceletId}`, requestOptions)  
    .then (response => response.json())
    .then (result =>{
        return result
    })
    .catch (error =>{ return error})
    return data
}

const createGamerBracelet = async (formData) =>{
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect : 'manual'
    };
    
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/gamer_bracelets`, requestOptions)  
    .then (response => response.json())
    .then (result =>{
        return result
    })
    .catch (error =>{ return error})
    return data
}
const deleteGamerBracelet = async (gamerBracelet_id) =>{
    var requestOptions = {
        method: 'DELETE',
        redirect : 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/gamer_bracelets/${gamerBracelet_id}`, requestOptions)  
    .then (response => response.json())
    .then (result =>{
        return result
    })
    .catch (error =>{ return error})
    return data
}

const getBraceletByBraceletSerialNumber = (formData) =>{
    return new Promise ((resolve, reject) =>{
        var requestOptions = {
            method: 'POST',
            body: JSON.stringify(formData),
            redirect : 'follow'
        };
        fetch(process.env.REACT_APP_API_BASEURL + `/bracelets/bracelet-serialnumber`, requestOptions)
        .then (response => response.json())
        .then (result =>{
            resolve (result)
        })
        .catch (error =>{ reject (error)})
    })
}

export default {
    getBraceletsInVersion,
    getGamerByBraceletId,
    getBraceletsById,
    getBracelets,
    createBracelet,
    deleteBracelet,
    createGamerBracelet,
    deleteGamerBracelet,
    getBraceletByBraceletSerialNumber
}


