import React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import prizeService from '../../services/prizes'
import * as XLSX from 'xlsx'

export default function PrizeImporter() {

    const [file, setFile] = useState('')
    const [formData, setFormData] = useState(null)
    const { version_id } = useParams()

    const handleFileChange = (e) => {
        const sheetsData = {}
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result)
            const workbook = XLSX.read(data,
                { type: 'array' }
            )
            workbook.SheetNames.forEach(sheetName => {
                const sheet = workbook.Sheets[sheetName]
                const dataSheet = XLSX.utils.sheet_to_json(sheet, { header: 1 })
                sheetsData[sheetName] = dataSheet.filter(row => row.length > 0).map((row, index) => {
                    const newRow = []
                    row.forEach((cell, column) => {
                        newRow.push(cell)
                    })
                    return newRow
                })
            })

            let version = {}

            Object.keys(sheetsData).forEach(sheet => {
                let sheetData = sheetsData[sheet]
                version[sheet] = []
                if (Array.isArray(sheetData)) { 
                    let prizeKindAmount = 0
                    sheetData.map((row, index) => {
                        let prize = {}
                        if (index == 0) {
                            prizeKindAmount = row[1]
                        } else {
                            prize = {
                                prizeBrand: row[0],
                                prizeName: row[1],
                                prizeScore: row[2],
                                version_id: version_id
                            }
                            version[sheet].push(prize)
                        }
                    })
                }
            })

            setFormData(version)
        }
        reader.readAsArrayBuffer(file)
    }

    const handleFileUpload = async (e) => {
        e.preventDefault()
        const result = await prizeService.importXLSX(version_id, formData)
    }
    return (
        <>
            {
                /*accept xlsx*/
            }
            <input
                type="file"
                id="file"
                accept=".xlsx"
                className="input-file"
                onChange={handleFileChange}
            />
            <button onClick={handleFileUpload}>Upload</button>
        </>
    )
}
