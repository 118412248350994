import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCol,
  MDBCardBody,
  MDBTabs,
  MDBTabsPane,
  MDBTabsContent,
  MDBCheckbox,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalTitle,
  MDBModalHeader,
  MDBModalContent,
  MDBInputGroup,
  MDBIcon,
} from "mdb-react-ui-kit";
import imagenHype2 from '../../img/textos/hype_rojo.png';
import imagenPasaporte from '../../img/textos/logo-pasaporte.png';
import imagenTituloAdministracion from '../../img/textos/titulo_administracion.png';
import imagenLogoExpogame from '../../img/textos/logo-expogame.png';
import userService from '../../services/users';
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const btnRecoverPassword = () => {
    navigate("/recover-password");
  };
  const btnHomeAdmin = () => {
    navigate("/profile");
  };
  const [basicOpen, setBasicOpen] = useState(false);
  const [adminEmail, setAdminEmail] = useState();
  const [adminPassword, setAdminPassword] = useState();
  const [errorModal, setErrorModal] = useState(false);
  const [varyingMessage, setVaryingMessage] = useState('');
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [inputFocussed, setInputFocussed] = useState(false);

  const login = async (e) => {
    e.preventDefault();

    const parameters = {
      userEmail: adminEmail,
      userPassword: adminPassword,
    };

    const result = await userService.adminSignIn(parameters)
    if (result.status === 400) {
      setVaryingMessage(result.messages.error),
        setErrorModal(!errorModal)
    } else {
      let data = atob(result.user)
      data = JSON.parse(data)

      if (parseInt(data.role_id) === 1) {
        localStorage.setItem('token', JSON.stringify(result));
        navigate('/home');
      } else {
        setVaryingMessage('No tienes permisos para acceder a esta sección');
        setErrorModal(!errorModal)
      }
    }

  };

  return (
    <div className="bg-white d-flex flex-column min-vh-100">
      <MDBContainer className="login-admin" fluid>
        <MDBRow className="d-flex justify-content-center align-items-center">

          <MDBCol md="6" className="d-flex flex-grow-1 justify-content-center align-items-center vh-100 page-cubes px-lg-5 px-3">
            <MDBCard className="border shadow-none w-100">
              <MDBCardBody>

                  <img
                    src={imagenLogoExpogame}
                    alt="Expogame"
                    className="img-fluid d-block mx-auto mb-0" 
                    width={200} 
                  />

                  <img
                    src={imagenTituloAdministracion}
                    alt="Administracion"
                    className="img-fluid d-block mx-auto mb-4"
                    width={300} 
                  />

                <form onSubmit={login}>

                  <MDBInput
                    className="mb-4"
                    type="email"
                    id="loginName"
                    label="Email"
                    onChange={(e) => setAdminEmail(e.target.value)}
                  />

                  <MDBInputGroup className='d-block mb-4 input-password'>
                    <MDBInput
                      className='form-control'
                      type={passwordIsVisible ? 'text' : 'password'}
                      id="loginPassword"
                      label="Contraseña"
                      onFocus={() => setInputFocussed(true)}
                      onBlur={() => setInputFocussed(false)}
                      onChange={e => setAdminPassword(e.target.value)}
                    />
                    <MDBBtn
                      className={inputFocussed ? 'icon-button-eye password-selected' : 'icon-button-eye'}
                      onClick={() => setPasswordIsVisible(!passwordIsVisible)}
                      type="button"
                    >
                      <MDBIcon icon={passwordIsVisible ? 'eye-slash' : 'eye'} />
                    </MDBBtn>
                  </MDBInputGroup>

                  <MDBBtn className='button-primary d-block w-100 my-4' outline color='dark' type="submit" >
                    Iniciar sesi&oacute;n
                  </MDBBtn>

                  <p className="text-center"><a href='' onClick={btnRecoverPassword} className="fs-5 text-dark">
                    ¿Olvidaste tu contraseña?
                  </a></p>

                  <a href="https://hypeproducciones.cl" target='_blank' className="d-block text-center mt-4"><img src={imagenHype2} alt="Hype Producciones" width={60} /></a>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol md="6" className='d-flex flex-grow-1 justify-content-center align-items-center vh-100 bg-admin d-none d-lg-block'>

          </MDBCol>
        </MDBRow>

        <MDBModal show={errorModal} setShow={setErrorModal} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Error</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => setErrorModal(!errorModal)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className='mb-3'>
                  {errorModal && varyingMessage}
                </div>
              </MDBModalBody>
              <MDBModalFooter>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </MDBContainer>{" "}
    </div>
  );
};

export default Login;
