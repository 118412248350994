import React, { useEffect, useState } from "react";
import MenuComponent from "../MenuComponent";
import {
    MDBCard,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBCardBody,
    MDBBtn,
    MDBAccordion,
    MDBAccordionItem
} from "mdb-react-ui-kit";
import prizeService from "../../services/prizes";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";


const VersionPrizeCodesAdmin = () => {

    const navigate = useNavigate();
    const { version_id } = useParams();

    const [prizes, setPrizes] = useState([]);
    const [state, setState] = useState(localStorage.getItem('token'));

    const [user, setUser] = useState({
        id: null,
        userFullName: "",
        userEmail: "",
        userPassword: "",
    });

    const onImageCownload = (svgDOM) => {
        const svg = svgDOM;
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };

    useEffect(() => {

        if (!state) {
            navigate('/login');
        } else {
            const newUser = JSON.parse(atob(JSON.parse(state).user))
            setUser(newUser);
        }

    }, []);

    const handleDataLoad = async () => {
        const result = await prizeService.getPrizesByVersion(version_id);
        setPrizes(result);
    }

    useEffect(() => {
        if (user.id != null) {
            handleDataLoad();
        }
    }, [user]);

    return (
        <>
            <div className="page-cubes d-flex flex-column min-vh-100">

                <MenuComponent onProfile={false} />
                <MDBContainer className="my-4">
                    <MDBRow>
                        <MDBCol
                            className="g-4 mt-0 mx-auto" 
                            style={
                                {
                                    maxWidth: "640px",
                                }
                            }
                        >
                            <MDBAccordion initialActive={1} className="faq my-4">
                                {
                                    prizes.map((prize, index) => {
                                        let code = JSON.stringify({
                                            type: "prize",
                                            prizeId: prize.id,
                                            prizeName: prize.prizeName,
                                            prizeScore: prize.prizeScore,
                                        });
                                        code = btoa(code);
                                        return (
                                            <MDBAccordionItem
                                                key={index}
                                                collapseId={`${index}`}
                                                headerTitle={`${prize.prizeBrand} - ${prize.prizeName}`}
                                            >
                                                <p>
                                                    Cristales: {prize.prizeScore}
                                                </p>
                                                <QRCode
                                                    id={`svg-${prize.id}`}
                                                    value={code}
                                                    style={
                                                        {
                                                            margin: "auto",
                                                            maxWidth: "100%",
                                                            width: "100%",
                                                        }
                                                    }

                                                />
                                                <MDBBtn
                                                    className="w-100 button-primary mt-4"
                                                    outline
                                                    color="dark"
                                                    onClick={
                                                        () => {
                                                            const svg = document.getElementById(`svg-${prize.id}`);
                                                            onImageCownload(svg);
                                                        }
                                                    }
                                                >
                                                    Descargar
                                                </MDBBtn>
                                            </MDBAccordionItem>
                                        )
                                    })
                                }
                            </MDBAccordion>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div >
        </>
    )
}

export default VersionPrizeCodesAdmin