import React, { useState, useEffect } from "react";
import moment from 'moment';
import momentTz from 'moment-timezone';
import versionService from "../../services/versions";
import stateService from "../../services/states";
import cityService from "../../services/cities";
import userService from "../../services/users";
import {
  MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon, MDBCardText, MDBBtn, MDBDropdown, MDBDropdownToggle,
  MDBDropdownMenu, MDBDropdownItem, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle,
  MDBModalBody, MDBModalFooter, MDBInput, MDBSelect, MDBDateTimepicker, MDBBadge, MDBCardFooter
} from "mdb-react-ui-kit";
import MenuComponent from '../MenuComponent';
import { useNavigate } from 'react-router-dom';

const VersionAdmin = () => {
  // Comprobación Login
  const navigate = useNavigate();
  const token = JSON.parse(window.localStorage.getItem("token"));
  if (token.status != 200) {
    navigate('/login');
  }

  const [isCurrentVersion, setIsCurrentVersion] = useState(false);
  // Definición de regiones
  const [region, setRegion] = useState([]);
  const [regionId, setRegionId] = useState(null);
  // Definición de ciudades
  const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState(null);
  // Definición de versiones para listar
  const [version, setVersion] = useState([{}]);
  // Definición de modal de versión a crear
  const [modalNewVersion, setModalNewVersion] = useState(false);
  const toggleShow = () => setModalNewVersion(!modalNewVersion);
  const [resetScoresModal, setResetScoresModal] = useState(false);
  const toggleShowReset = () => setResetScoresModal(!resetScoresModal);
  // Definición de modal de versión a editar
  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal(!editModal)
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  // Definición de modal de exito
  const [modalExito, setmodalExito] = useState(false);
  const toggleModalExito = () => setmodalExito(!modalExito);
  // Definición de fechas para editar/ingresar versión
  const [activationSelectedDate, setActivationSelectedDate] = useState(null);
  const [selectedStartDate, setStartSelectedDate] = useState(null);
  const [selectedEndDate, setEndSelectedDate] = useState(null);
  // Definición de versión para editar
  const [selectedVersion, setSelectedVersion] = useState({
    id: "",
    city: {},
    city_id: "",
    versionName: "",
    versionAnnouncementDate: activationSelectedDate,
    versionOpeningDate: selectedStartDate,
    versionClosingDate: selectedEndDate
  })
  const [defaultDateTimeData, setDefaultDateTimeData] = useState({
    defaultActivationDate: "",
    defaultStartDate: "",
    defaultEndDate: "",
    defaultActivationTime: "",
    defaultStartTime: "",
    defaultEndTime: ""
  })
  const [currentVersion, setCurrentVersion] = useState({
  });

  const currentVers = async () => {
    const currentVers = await versionService.getVersionCurrent();
    setCurrentVersion(currentVers);
  }

  currentVers();

  // Definición de ID de version
  const [selectedVersionId, setSelectedVersionId] = useState(null);
  // Edicion versión
  const setVers = async (version) => {

    const vers = await versionService.getVersionById(version.id);

    let newVers = {
      ...version
    }

    newVers.versionAnnouncementDate = dateFormatToDatePicker(vers.versionAnnouncementDate);
    newVers.versionOpeningDate = dateFormatToDatePicker(vers.versionOpeningDate);
    newVers.versionClosingDate = dateFormatToDatePicker(vers.versionClosingDate);

    setDefaultDateTimeData({
      defaultActivationDate: version.defaultActivationDate,
      defaultStartDate: version.defaultStartDate,
      defaultEndDate: version.defaultEndDate,
      defaultActivationTime: version.defaultActivationTime,
      defaultStartTime: version.defaultStartTime,
      defaultEndTime: version.defaultEndTime
    })

    setRegionId(version.city.state_id)
    setSelectedVersion(newVers)

    setTimeout(() => {
      toggleEditModal()
    }, 200);
  }


  const checkCurrentVersion = () => {
    if (selectedVersion.id != '') {
      if (selectedVersion.id == currentVersion.id) {

        setIsCurrentVersion(true);
      } else {
        setIsCurrentVersion(false);
      }
    }
  }

  useEffect(() => {
    console.log(selectedVersion)
    if (selectedVersion.id != '') {
      checkCurrentVersion()
    }
  }, [selectedVersion])
  useEffect(() => {
    console.log(isCurrentVersion);
  }, [isCurrentVersion])

  // Data para ingresar nueva versión
  const [formData, setFormData] = useState({
    city_id: cityId,
    versionName: "",
    versionAnnouncementDate: activationSelectedDate,
    versionOpeningDate: selectedStartDate,
    versionClosingDate: selectedEndDate
  })
  // Handlers
  // Cambio de datos de formulario para ingresar
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      city_id: cityId
    });
  };

  // Carga de lista de versiones
  const handleVersionDataLoad = async () => {
    const newVersions = await versionService.getVersions();
    let currentDate = new Date();

    const loadedVersions = newVersions.map((newVersion) => {

      //if currentDate is between versionAnnouncementDate and versionClosingDate

      if (currentDate >= new Date(newVersion.versionAnnouncementDate) && currentDate <= new Date(newVersion.versionClosingDate)) {
        newVersion.versionStatus = "Activa";
      } else {
        newVersion.versionStatus = "Inactiva";
      }

      return {
        id: newVersion.id,
        city_id: newVersion.city_id,
        versionName: newVersion.versionName,
        city: newVersion.city,
        versionAnnouncementDate: newVersion.versionAnnouncementDate,
        versionOpeningDate: newVersion.versionOpeningDate,
        versionClosingDate: newVersion.versionClosingDate,
        localeFormatOD: "Inicio: " + moment(newVersion.versionOpeningDate).format('DD-MM-YYYY, HH:mm'),
        localeFormatCD: "Cierre: " + moment(newVersion.versionClosingDate).format('DD-MM-YYYY, HH:mm'),
        defaultActivationDate: moment((newVersion.versionAnnouncementDate)).format('DD-MM-YYYY'),
        defaultActivationTime: moment((newVersion.versionAnnouncementDate)).format('h:mm A'),
        defaultStartDate: moment((newVersion.versionOpeningDate)).format('DD-MM-YYYY'),
        defaultStartTime: moment((newVersion.versionOpeningDate)).format('h:mm A'),
        defaultEndDate: moment((newVersion.versionClosingDate)).format('DD-MM-YYYY'),
        defaultEndTime: moment((newVersion.versionClosingDate)).format('h:mm A'),
        versionStatus: newVersion.versionStatus
      }
    })
    setVersion(loadedVersions)
  }


  // Carga de lista de regiones
  const handleStateDataLoad = async () => {
    const newStates = await stateService.getStates();
    const states = newStates.map((newState) => ({
      id: newState.id,
      stateName: newState.stateName,
    }))
    setRegion(states)
  }
  // Carga de lista de ciudades dependiendo de la región seleccionada
  const handleCityDataLoad = async (state_id) => {
    const newCities = await cityService.getCitiesByState(state_id);
    const cities = newCities.map((newCity) => ({
      id: newCity.id,
      cityName: newCity.cityName,
      state_id: newCity.city_id,
      state: newCity.state,
    }))
    setCity(cities)
  }
  const handleDateChangeActivation = date => {
    setActivationSelectedDate(date);
  };
  const handleDateChangeStart = date => {
    setStartSelectedDate(date);
  }
  const handleDateChangeEnd = date => {
    setEndSelectedDate(date);
  };

  const dateFormatToMySQL = (date) => {
    let dateArray = date.split(", ");
    let datePart = dateArray[0].split("-");
    let timePart = dateArray[1].split(":");
    let newDate = datePart[2] + "-" + datePart[1] + "-" + datePart[0] + " " + timePart[0] + ":" + timePart[1] + ":00";
    return newDate;
  }

  const dateFormatToDatePicker = (date) => {
    let dateArray = date.split(" ");
    let datePart = dateArray[0].split("-");
    let timePart = dateArray[1].split(":");
    let newDate = datePart[2] + "-" + datePart[1] + "-" + datePart[0] + ", " + timePart[0] + ":" + timePart[1];
    return newDate;
  }

  const handleVersionCreation = async (e) => {
    e.preventDefault();

    let newData = {
      ...formData,
      versionAnnouncementDate: dateFormatToMySQL(activationSelectedDate),
      versionOpeningDate: dateFormatToMySQL(selectedStartDate),
      versionClosingDate: dateFormatToMySQL(selectedEndDate)
    }
    const response = await versionService.createVersion(newData);
    if (response.status === 400) {
      console.log('error', response);
    } else {
      setModalNewVersion(!modalNewVersion);
      setmodalExito(!modalExito);
      handleVersionDataLoad();
    }


  }
  // Actualización de versión
  const handleVersionUpdate = async (e) => {
    e.preventDefault();

    const result = await versionService.getVersionById(selectedVersion.id);

    let newData = {
      ...result,
    }


    newData = {
      ...selectedVersion,
      versionAnnouncementDate: dateFormatToMySQL(activationSelectedDate || selectedVersion.versionAnnouncementDate),
      versionOpeningDate: dateFormatToMySQL(selectedStartDate || selectedVersion.versionOpeningDate),
      versionClosingDate: dateFormatToMySQL(selectedEndDate || selectedVersion.versionClosingDate)
    }

    const response = await versionService.updateVersion(selectedVersion.id, newData);

    if (response.status === 400) {
      console.log('error', response);
    } else {
      setEditModal(!editModal);
      setmodalExito(!modalExito);
      handleVersionDataLoad();
    }


  }
  // Eliminacion de versión
  const handleVersionDeletion = async (e) => {
    e.preventDefault();
    const response = await versionService.deleteVersion(selectedVersionId);
    if (response.status === 400) {
      console.log('error', response);
    } else {
      toggleDeleteModal()
      handleVersionDataLoad();
    }
  }

  const handleResetScores = async (e) => {
    e.preventDefault();
    const response = await userService.resetScores();
    if (response.status === 400) {
      console.log('error', response);
    } else {
      toggleShowReset();
    }
  }

  // UseEffect
  // Cambio de regiones
  useEffect(() => {
    if (version != []) {
      handleVersionDataLoad();
    }
    if (region != []) {
      handleStateDataLoad();
    }
    if (city != [] && regionId) {
      handleCityDataLoad(regionId);
    }
  }, [regionId])

  // Cambio de ciudades
  useEffect(() => {
    if (cityId) {
      setFormData({
        ...formData,
        city_id: cityId
      });
    }
  }, [cityId])
  // Cambio de Fechas
  useEffect(() => {
    if (selectedEndDate !== null) {
      setFormData({
        ...formData,
        versionAnnouncementDate: activationSelectedDate,
        versionOpeningDate: selectedStartDate,
        versionClosingDate: selectedEndDate
      });
    }
  }, [selectedEndDate])
  // Cambio de id de version
  useEffect(() => {
    if (selectedVersionId !== null) {
      toggleEditModal();
      toggleDeleteModal();
    }
  }, [selectedVersionId])


  return (
    <div className="page-admin d-flex flex-column min-vh-100">
      <MenuComponent onProfile={false} />
      <MDBContainer className="py-5">
        <>
          <MDBContainer className="justify-content-center text-left ">
            <MDBRow className="d-flex align-items-center py-4">
              <MDBCol className="col-md-6 col-12">
                <h3 className="fw-bold">Administrador de Eventos</h3>
              </MDBCol>
              <MDBCol className="col-md-6 col-12 text-end">
                <div className="d-grid gap-2 col-12 d-md-block">
                  <MDBBtn
                    onClick={() => {
                      toggleShowReset();
                    }}
                    className="py-0 px-3 mx-3"
                    outline
                    color="dark"
                  >
                    Reset Puntaje{" "}
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => {
                      toggleShow();
                    }}
                    className="button-primary py-0 px-3"
                    outline
                    color="dark"
                  >
                    Crear evento{" "}
                  </MDBBtn>
                  
                  {/*}
                  <MDBDropdown group className="shadow-0 ms-md-3">
                    <MDBDropdownToggle
                      className="py-0 px-3"
                      color="dark"
                      outline
                    >
                      Año
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem link>2023</MDBDropdownItem>
                      <MDBDropdownItem link>2022</MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                  */}
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className="row-cols-md-2 row-cols-1 g-4">
              {version.map((vers, index) => {
                return (
                  <MDBCol key={index} className="bg-image hover-zoom">
                    <MDBCard className="shadow-none border">
                      <MDBCardBody>
                        <MDBIcon
                          far
                          icon="calendar-alt"
                          size="2x"
                          className="panel-icons mb-4 ps-1"
                        />
                        <h4
                          className="mb-4 ps-1"
                        > Expogame {vers.versionName}
                        </h4>
                        <p className="fs-6 fw-normal ps-1">{vers.localeFormatOD}</p>
                        <p className="fs-6 fw-normal ps-1">{vers.localeFormatCD}</p>
                        <MDBBtn
                          onClick={() => {
                            setVers(vers);
                          }}
                          className="button-primary py-0 px-3 m-1"
                          outline
                          color="dark"
                        >
                          Ver Evento
                        </MDBBtn>
                        <span />
                        <MDBBtn
                          onClick={() => {
                            navigate(`/bracelets/${vers.id}`)
                          }}
                          className="button-secondary py-0 px-3 m-1 "
                          outline
                          color="dark"
                        >
                          Pulseras
                        </MDBBtn>
                      </MDBCardBody>
                      {
                        vers.versionStatus === 'Activa' && <>
                          <MDBCardFooter>
                            <MDBBtn
                              className="button-outline py-0 px-3 mb-3 m-1"
                              outline
                              color="dark"
                              onClick={() => {
                                navigate(`/version-challenge-codes/${vers.id}`)
                              }}
                            >
                              QR Retos
                            </MDBBtn>
                            <MDBBtn
                              className="button-outline py-0 px-3 mb-3 m-1"
                              outline
                              color="dark"
                              onClick={() => {
                                navigate(`/version-prize-codes/${vers.id}`)
                              }}
                            >
                              QR Premios
                            </MDBBtn>
                          </MDBCardFooter>
                        </>
                      }
                    </MDBCard>
                  </MDBCol>
                )
              })}
            </MDBRow>
          </MDBContainer>
          <MDBModal
            md="12"
            tabIndex="-1"
            show={modalNewVersion}
            setShow={setModalNewVersion}
          >
            <MDBModalDialog size="lg" centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Próximo evento</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleShow}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <form>
                    <MDBInput
                      className="mb-3"
                      type="text"
                      name="versionName"
                      id="versionName"
                      label="Nombre de Versión"
                      value={formData.versionName}
                      onChange={handleChange}
                    />
                    {region.length > 0 && (
                      <MDBSelect
                        className="mb-3"
                        onValueChange={(e) => setRegionId(e.value)}
                        preventFirstSelection
                        placeholder="Seleccione una región"
                        data={region.map((region) => ({
                          text: region.stateName,
                          value: region.id,
                          defaultSelected: region.id == regionId,
                        }))}
                      />
                    )}
                    {regionId && (
                      <MDBSelect
                        className="mb-3"
                        onValueChange={(e) => setCityId(e.value)}
                        label="Ciudad o comuna"
                        data={city.map((city) => ({
                          text: city.cityName,
                          value: city.id,
                          // defaultSelected: city.id == cityId
                        }))}
                      />
                    )}
                    <MDBDateTimepicker
                      dateFormat="dd-mm-yyyy"
                      timeFormat="24h"
                      inline
                      label="Fecha de activación"
                      className="mb-3"
                      value={activationSelectedDate}
                      inputToggle
                      onChange={handleDateChangeActivation}
                    />
                    <MDBDateTimepicker
                      dateFormat="dd-mm-yyyy"
                      timeFormat="24h"
                      inline
                      label="Fecha de Inicio"
                      className="mb-3"
                      value={selectedStartDate}
                      inputToggle
                      onChange={handleDateChangeStart}
                    />
                    <MDBDateTimepicker
                      dateFormat="dd-mm-yyyy"
                      timeFormat="24h"
                      inline
                      label="Fecha de Termino"
                      className="mb-3"
                      value={selectedEndDate}
                      inputToggle
                      onChange={handleDateChangeEnd}
                    />
                  </form>
                </MDBModalBody>
                {cityId && selectedEndDate && (
                  <MDBModalFooter>
                    <MDBBtn
                      className="button-primary"
                      outline
                      color="dark"
                      onClick={handleVersionCreation}
                    >
                      Guardar
                    </MDBBtn>
                  </MDBModalFooter>
                )}
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          <MDBModal staticBackdrop tabIndex="-1" show={modalExito} setShow={setmodalExito}>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Exito!</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleModalExito}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <p>Datos Ingresados!</p>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn onClick={toggleModalExito}>Aceptar </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          <MDBModal tabIndex='-1' show={editModal} setShow={setEditModal}>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle> Editar version {selectedVersion.versionName}</MDBModalTitle>
                  {
                    !isCurrentVersion &&
                    <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#dd4b39' }} onClick={() => { setSelectedVersionId(selectedVersion.id) }}>
                      <MDBIcon fas icon="trash-alt" size="2x" />
                    </MDBBtn>
                  }
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBInput className="mb-3"
                    label="Nombre Versión"
                    value={selectedVersion.versionName}
                    onChange={(e) =>
                      setSelectedVersion({
                        ...selectedVersion,
                        versionName: e.target.value
                      })
                    } />
                  {region.length > 0 && (
                    <MDBSelect
                      className="mb-3"
                      onValueChange={(e) => setRegionId(e.value)}
                      preventFirstSelection
                      placeholder="Seleccione una región"
                      data={region.map((region) => ({
                        text: region.stateName,
                        value: region.id,
                        defaultSelected: region.id == selectedVersion.city.state_id,
                      }))}
                    />
                  )}
                  {regionId && (
                    <MDBSelect
                      className="mb-3"
                      onValueChange={(e) => setSelectedVersion({
                        ...selectedVersion,
                        city_id: e.value
                      })}
                      label="Ciudad o comuna"
                      data={city.map((city) => ({
                        text: city.cityName,
                        value: city.id,
                        defaultSelected: city.id == selectedVersion.city_id
                      }))}
                    />
                  )}
                  <MDBBadge >Fecha de activación</MDBBadge>
                  <MDBDateTimepicker
                    dateFormat="dd-mm-yyyy"
                    timeFormat="24h"
                    inline
                    label={selectedVersion.versionAnnouncementDate}
                    className="mb-3"
                    value={activationSelectedDate}
                    inputToggle
                    onChange={handleDateChangeActivation}
                  />

                  <MDBBadge>Fecha de Inicio</MDBBadge>
                  <MDBDateTimepicker
                    dateFormat="dd-mm-yyyy"
                    timeFormat="24h"
                    inline
                    label={selectedVersion.versionOpeningDate}
                    className="mb-3"
                    value={selectedStartDate}
                    inputToggle
                    onChange={handleDateChangeStart}
                  />
                  <MDBBadge >Fecha de cierre</MDBBadge>
                  <MDBDateTimepicker
                    dateFormat="dd-mm-yyyy"
                    timeFormat="24h"
                    inline
                    label={selectedVersion.versionClosingDate}
                    className="mb-3"
                    value={selectedEndDate}
                    inputToggle
                    onChange={handleDateChangeEnd}
                  />
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn
                    className="button-primary"
                    outline
                    color="dark"
                    onClick={
                      handleVersionUpdate} >Guardar</MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          <MDBModal tabIndex='-1' show={deleteModal} setShow={setDeleteModal}>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Eliminar versión Expogame {selectedVersion.versionName}</MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody>
                  <p>
                    ¿Está seguro de eliminar esta versión?
                  </p>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn onClick={handleVersionDeletion}> Sí </MDBBtn>
                  <MDBBtn onClick={toggleDeleteModal}> Cancelar </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          <MDBModal
            md="12"
            tabIndex="-1"
            show={resetScoresModal}
            setShow={setResetScoresModal}
            className="bg-danger"
          >
            <MDBModalDialog
              size="lg" centered
            >
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Reiniciar puntajes</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleShowReset}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <p>
                    ¿Está seguro de reiniciar los puntajes de todas las pulseras?
                  </p>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn
                    outline
                    color="dark"
                    onClick={toggleShowReset}
                  >
                    Cancelar
                  </MDBBtn>
                  <MDBBtn
                    className="button-primary"
                    outline
                    color="dark"
                    onClick={handleResetScores}
                  >
                    Reiniciar puntajes
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </>
      </MDBContainer>
    </div>
  );
}
export default VersionAdmin;