const getVersions = async () => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/versions`, requestOptions)
    .then(response => response.json())
    .then(result => {
        return result
    })
    .catch(error => console.log('error', error));

    return data
}
const getVersionById = async(version_id) =>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/versions/${version_id}`, requestOptions)
    .then(response => response.json())
    .then(result => {
        return result
    })
    .catch(error => console.log('error', error));
    return data
}


const getVersionCurrent = async () => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/versions/current`, requestOptions)
    .then(response => response.json())
    .then(result => {
        return result
    })
    .catch(error => console.log('error', error));

    return data
}

const getVersionByCity = async (city_id) =>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/versions/city/${city_id}`, requestOptions)
    .then(response => response.json())
    .then(result => {
        return result
    })
    .catch(error => console.log('error', error));

}
const createVersion  = async (formData) =>{
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect : 'manual',
    }
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/versions`, requestOptions)
    .then (response => response.json())
    .then (result =>{
        return result
    }) 
    .catch( error => {return error})
    return data;
}

const updateVersion = async (id, selectedVersion) =>{
    var requestOptions = {
        method: 'PATCH',
        body: JSON.stringify(selectedVersion),
        redirect: 'manual'
    }
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/versions/${id}` , requestOptions)
    .then (response => response.json())
    .then (result =>{
        return result
    })
    .catch( error =>{return error})

    return data;
}
const deleteVersion = async (version_id) =>{
    var requestOptions = {
        method: 'DELETE',
        redirect: 'manual'
    }    
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/versions/${version_id}`, requestOptions)
    .then(response => response.json())
    .then(result => {
        return result
    })
    .catch(error => {return error});
    return data
}

export default {
    getVersions,
    createVersion,
    getVersionByCity,
    getVersionById,
    getVersionCurrent,
    updateVersion,
    deleteVersion
};