import React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import challengeService from '../../services/challenges'
import * as XLSX from 'xlsx'

export default function ChallengeImporter() {

    const [file, setFile] = useState('')
    const [formData, setFormData] = useState(null)
    const { version_id } = useParams()

    const xlsDateToJSDate = (serial) => {
        var utc_days = Math.floor(serial - 25569);
        var utc_value = utc_days * 86400;
        var date_info = new Date(utc_value * 1000);
        var fractional_day = serial - Math.floor(serial) + 0.0000001;
        var total_seconds = Math.floor(86400 * fractional_day);
        var seconds = total_seconds % 60;
        total_seconds -= seconds;
        var hours = Math.floor(total_seconds / (60 * 60));
        var minutes = Math.floor(total_seconds / 60) % 60;
        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    }

    const handleFileChange = (e) => {
        const sheetsData = {}
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result)
            const workbook = XLSX.read(data,
                { type: 'array' }
            )
            workbook.SheetNames.forEach(sheetName => {
                const sheet = workbook.Sheets[sheetName]
                const dataSheet = XLSX.utils.sheet_to_json(sheet, { header: 1 })
                sheetsData[sheetName] = dataSheet.filter(row => row.length > 0).map((row, index) => {
                    const newRow = []
                    row.forEach((cell, column) => {
                        if (index == 0) {
                            if ([0, 1].includes(column))
                                newRow.push(cell)
                            else if ([2, 3, 4].includes(column)) {
                                let newDate = xlsDateToJSDate(cell);
                                newRow.push(newDate)
                            }
                        }
                        else {
                            newRow.push(cell)
                        }
                    })
                    return newRow
                })
            })

            let version = {}
            let zones = {}

            version.versionName = sheetsData[workbook.SheetNames[0]][0][1].toString().trim()
            version.versionAnnouncementDate = sheetsData[workbook.SheetNames[0]][0][2].toString().trim()
            version.versionOpeningDate = sheetsData[workbook.SheetNames[0]][0][3].toString().trim()
            version.versionClosingDate = sheetsData[workbook.SheetNames[0]][0][4].toString().trim()

            Object.keys(sheetsData).forEach(sheet => { 
                zones[sheet] = {
                    zoneName: sheetsData[sheet][1][1],
                }
                let validatorUsers = sheetsData[sheet][2][1]
                if (validatorUsers > 0) {
                    zones[sheet] = {
                        ...zones[sheet],
                        validatorUsers: []
                    }
                    for(let i = 0; i < validatorUsers; i++) {
                        zones[sheet].validatorUsers.push({
                            userFullName: sheetsData[sheet][3 + i][0].toString().trim(),
                            userDNI: sheetsData[sheet][3 + i][1].toString().trim(),
                            userEmail: sheetsData[sheet][3 + i][2].toString().trim(),
                            userPhone: sheetsData[sheet][3 + i][3].toString().trim(),
                        })
                    }
                    console.log(zones[sheet].validatorUsers)
                }
                let challenges = sheetsData[sheet][3 + validatorUsers][1]
                if (challenges > 0) {
                    zones[sheet] = {
                        ...zones[sheet],
                        challenges: []
                    }
                    for(let i = 0; i < challenges; i++) {
                        zones[sheet].challenges.push({
                            challengeName: sheetsData[sheet][4 + validatorUsers + i][0].toString().trim(),
                            challengeActivity: sheetsData[sheet][4 + validatorUsers + i][1].toString().trim(),
                            challengeDescription: sheetsData[sheet][4 + validatorUsers + i][2].toString().trim(),
                            challengeDifficulty: sheetsData[sheet][4 + validatorUsers + i][3].toString().trim(),
                            challengeScore: sheetsData[sheet][4 + validatorUsers + i][4].toString().trim(),
                        })
                    }
                }
            })
            version.zones = zones
            setFormData(version)
        }
        reader.readAsArrayBuffer(file)
    }

    const handleFileUpload = async (e) => {
        e.preventDefault()
        const result = await challengeService.importXLSX(version_id, formData)
        console.log(result)
    }
    return (
        <>
            {
                /*accept xlsx*/
            }
            <input
                type="file"
                id="file"
                accept=".xlsx"
                className="input-file"
                onChange={handleFileChange}
            />
            <button onClick={handleFileUpload}>Upload</button>
        </>
    )
}
