import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Login from "./LoginAdmin";
import {
    MDBNavbar,
    MDBContainer, 
    MDBScrollbar,
    MDBBtn,
    MDBIcon,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBRow, MDBCol
} from 'mdb-react-ui-kit';
import MenuComponent from '../MenuComponent';

const HomeAdmin = () => {
    const navigate = useNavigate();

    const state = JSON.parse(window.localStorage.getItem('token'));

    if (!state) {
        {
            return (
                <Login />
            )
        }
    }

    const [token, setToken] = useState(state.user);

    const btnUserAdmin = () => {
        navigate('/users', { state: { token: token } });
    }
    const btnVersionAdmin = () =>{
        navigate('/versions');
    }
    const btnNotificationAdmin = () => {
        navigate('/notification-management');
    }

    return (
        <div className="page-admin d-flex flex-column min-vh-100">
            <MenuComponent onProfile={false} />
            <MDBContainer className='py-5'>
                <>
                    <MDBContainer className="px-4 justify-content-center text-left ">
                        <MDBRow className='g-4'>
                            <MDBCol md="4 " className='bg-image hover-zoom' >
                                <MDBCard className='shadow-none border'>
                                    <MDBCardBody>
                                        <MDBIcon fas icon="users-cog" size='2x' className='panel-icons mb-4' />

                                        <MDBCardText className='fs-5 fw-bold'>
                                            Administrador de usuarios
                                        </MDBCardText>
                                        <MDBBtn href='#' onClick={btnUserAdmin} className='button-primary py-0 px-3' outline color='dark' >Ver Más</MDBBtn>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md="4">
                                <MDBCard className='shadow-none border'>
                                    <MDBCardBody>
                                        <MDBIcon fas icon="calendar-alt" size='2x' className='panel-icons mb-4' />

                                        <MDBCardText className='fs-5 fw-bold'>
                                            Administrador de versiones
                                        </MDBCardText>
                                        <MDBBtn href='#' onClick={btnVersionAdmin} className='button-primary py-0 px-3' outline color='dark' >Ver Más</MDBBtn>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md="4">
                                <MDBCard className='shadow-none border'>
                                    <MDBCardBody>
                                        <MDBIcon fas icon="list" size='2x' className='panel-icons mb-4' />

                                        <MDBCardText className='fs-5 fw-bold'>
                                            Administrador de notificaciones
                                        </MDBCardText>
                                        <MDBBtn href='#' onClick={btnNotificationAdmin} className='button-primary py-0 px-3' outline color='dark' >Ver Más</MDBBtn>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </>
            </MDBContainer>
        </div>
    )
}
export default HomeAdmin