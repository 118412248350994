
const getZonesByVersion = async (version_id) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/zones/version/${version_id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => console.log('error', error));
    return data
}

export default {
    getZonesByVersion,
};