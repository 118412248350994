import React, { useState, useEffect } from "react";
import {
  MDBSelect,
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBNavbarLink, MDBTextArea,
  MDBCollapse,
  MDBBtn,
  MDBIcon,
  MDBNavbarNav,
  MDBInputGroup,
  MDBIcons,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBDatatable,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalTitle,
  MDBModalHeader,
  MDBModalContent,
  MDBScrollbar,
} from "mdb-react-ui-kit";
import UserController from "../../Controller/UserController";
import { MDBTableEditor } from "mdb-react-table-editor";
import imagenLogo from "../../img/textos/logo-expogame.png";
import icon01 from "../../img/iconos/icon01.svg";
import icon02 from "../../img/iconos/icon02.svg";
import icon03 from "../../img/iconos/icon03.svg";
import icon04 from "../../img/iconos/icon04.svg";
import { useLocation, useNavigate } from "react-router-dom";
import userService from "../../services/users";
import stateService from "../../services/states";
import cityService from "../../services/cities";
import MenuComponent from "../MenuComponent";
import { CSVLink } from "react-csv"
import * as XLSX from "xlsx";
import moment from 'moment';

const UserAdmin = () => {
  const navigate = useNavigate();
  const [basicOpen, setBasicOpen] = useState(false);
  const [basicCollapse1, setBasicCollapse1] = useState(true);
  const [basicCollapse2, setBasicCollapse2] = useState(false);
  const [iconsActive, setIconsActive] = useState(1);

  const [user, setUser] = useState({});
  const [usuarioAEliminar, setUsuarioAEliminar] = useState();
  const [usuarioEliminado, setUsuarioEliminado] = useState();
  const [gamers, setGamers] = useState([]);
  const [users, setUsers] = useState([]);

  const [regionId, setRegionId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [region, setRegion] = useState([])
  const [city, setCity] = useState([])

  const state = JSON.parse(window.localStorage.getItem("token"));


  const handleIconsClick = async (value) => {
    if (value === iconsActive) {
      return;
    }
    value = parseInt(value);
    setIconsActive(value);
    if (value === 4) {
      await handleGamerDataLoad();
    }
  };

  const [listaAdmin, setListaAdmin] = useState({
    columns: [],
    rows: [],
  });
  const handleGamerDataLoad = async () => {
    const newGamers = await userService.getGamers();
   
    for (let i = 0; i < newGamers.length; i++) {
      let gamerDNI = newGamers[i].gamerDNI;
      //get last character
      let lastCharacter = gamerDNI.slice(-1);
      //get first 8 characters
      let firstEightCharacters = gamerDNI.slice(0, -1);
      //set firstEightCharacters as a number separated by dots (thousands)
      let firstEightCharactersDots = firstEightCharacters.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      //concatenate firstEightCharactersDots and lastCharacter
      let gamerDNIFormatted = firstEightCharactersDots + "-" + lastCharacter;
      newGamers[i].gamerDNI = gamerDNIFormatted;
    }

    setGamers(newGamers);
  };



  const handleUserDataLoad = async (role_id) => {
    const newUsers = await userService.getAdmins(role_id);

    for (let i = 0; i < newUsers.length; i++) {
      let userDNI = newUsers[i].userDNI;
      //get last character
      let lastCharacter = userDNI.slice(-1);
      //get first 8 characters
      let firstEightCharacters = userDNI.slice(0, -1);
      //set firstEightCharacters as a number separated by dots (thousands)
      let firstEightCharactersDots = firstEightCharacters.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      //concatenate firstEightCharactersDots and lastCharacter
      let userDNIFormatted = firstEightCharactersDots + "-" + lastCharacter;
      newUsers[i].userDNI = userDNIFormatted;
    }
    setUsers(newUsers);
  }

  const handleCrud = async () => {
    if (iconsActive != 4) {
      if (users) {
        const rows = users.map((user) => ({
          id: user.id,
          userDNI: user.userDNI,
          userFullName: user.userFullName,
          userPhone: user.userPhone,
          role: user.role.roleName,         
          created_at:  moment(user.created_at.date).format('DD-MM-YYYY, HH:mm'),
          userEmail: user.userEmail,
        })).reverse();
        const columns = [
          {
            width: 150,
            label: "Rut",
            field: "userDNI",
          },
          {
            width: 150,
            label: "Nombre Completo",
            field: "userFullName",
          },
          {
            width: 150,
            label: "Email",
            field: "userEmail",
          },
          {
            width: 150,
            label: "Teléfono",
            field: "userPhone",
          },
          {
            width: 150,
            label: "Rol",
            field: "role",
          },
          {
            width: 30,
            label: "Fecha de creación",
            field: "created_at",
          },
          { label: "Acciones", field: "action", sort: false },
        ];
        setListaAdmin({
          columns: columns,
          rows: rows.map((row) => {
            return {
              ...row,
              action: (
                <>
                  <MDBBtn
                    color="light"
                    rippleColor="dark"
                    onClick={() => toggleShow(row)}
                  >
                    <MDBIcon fas icon="edit" />
                  </MDBBtn>
                  <MDBBtn
                    color="light"
                    rippleColor="dark"
                    onClick={() => togglemodalConfirmacion(row.id)}
                  >
                    <MDBIcon fas icon="trash-alt" />
                  </MDBBtn>
                </>
              ),
            };
          }),
        });
      }
    } else if (iconsActive == 4) {
      if (gamers) {
        const rows = gamers.map((gamer) => ({
          id: gamer.id,
          city_id: gamer.city_id,
          city: gamer.city,
          avatar_id: gamer.avatar_id,
          gamerName: gamer.gamerName,
          gamerFullName: gamer.gamerFullName,
          gamerAge: gamer.gamerAge,
          gamerDNI: gamer.gamerDNI,
          gamerPhone: gamer.gamerPhone,
          gamerEmail: gamer.gamerEmail,
          gamerExperiencePoints: gamer.gamerExperiencePoints,
         created_at: moment(gamer.created_at.date).format('DD-MM-YYYY, HH:mm'),
        })).reverse();
        const columns = [
          {
            width: 150,
            label: "Nombre de Usuario",
            field: "gamerName",
          },
          {
            width: 150,
            label: "Rut",
            field: "gamerDNI",
          },
          {
            width: 150,
            label: "Edad",
            field: "gamerAge",
          },
          {
            width: 150,
            label: "Nombre Completo",
            field: "gamerFullName",
          },
          {
            width: 30,
            label: "Fecha de creación",
            field: "created_at",
          },
          {
            width: 250,
            label: "Email",
            field: "gamerEmail",
          },
          {
            width: 150,
            label: "Teléfono",
            field: "gamerPhone",
          },
          {
            width: 100,
            label: "Experiencia",
            field: "gamerExperiencePoints",
          },
          { label: "Acciones", field: "action", sort: false },
        ];
        setListaAdmin({
          columns: columns,
          rows: rows.map((row) => {
            return {
              ...row,
              action: (
                <>
                  <MDBBtn
                    color="light"
                    rippleColor="dark"
                    onClick={() => {
                      toggleShow3(row);
                      setRegionId(row.city.state_id)
                      setCentredModal3(true);
                    }
                    }
                  >
                    <MDBIcon fas icon="edit" />
                  </MDBBtn>
                  <MDBBtn
                    color="light"
                    rippleColor="dark"
                    onClick={() => togglemodalConfirmacion(row.id)}
                  >
                    <MDBIcon fas icon="trash-alt" />
                  </MDBBtn>
                </>
              ),
            };
          }),
        });
      }
      /*
      else {
        setVaryingMessage("Jugadores no encontrados")
        setErrorModal(!errorModal);
      }
      */
    }
  };

  const handleDataCrudTrigger = async () => {
    if (iconsActive != 4) {
      await handleUserDataLoad(iconsActive);
    } else {
      await handleGamerDataLoad();
    }
  }

  useEffect(() => {
    handleDataCrudTrigger()
  }, [iconsActive]);

  useEffect(() => {
    if (gamers != []) {
      handleCrud();
      
    }
  }, [gamers]);

  useEffect(() => {
    if (users != []) {
      handleCrud();
    }
  }, [users]);

  const [roleData, setRoleData] = useState([]);
  useEffect(() => {
    setUser(JSON.parse(atob(state.user)));
    fetch(process.env.REACT_APP_API_BASEURL + "/roles")
      .then((response) => response.json())
      .then((data) => {
        const roles = data.map((item) => ({
          id: item.id,
          roleName: item.roleName,
        }));
        setRoleData(roles);
      });
  }, []);

  const handleStateLoad = async () => {
    let states = await stateService.getStates();
    setRegion(states)
  }

  useEffect(() => {
    handleStateLoad()
  }, []);

  const handleCitiesLoad = async () => {
    if (!regionId) return;
    let cities = await cityService.getCitiesByState(regionId);
    if (cities.length == 0) {
      cities = [{ id: 0, cityName: "Sin ciudades" }]
    } else {
      setCity(cities);
    }
  }

  useEffect(() => {
    handleCitiesLoad()

  }, [regionId])

  const csvData = [
    listaAdmin.columns.filter(
      (column) => column.field != "action"
    ).map(
      (column) => column.label
    ),
    ...listaAdmin.rows.map(
      (row) => listaAdmin.columns.filter(
        (column) => column.field != "action"
      ).map((column) => row[column.field])
    )
  ]

  useEffect(() => {
    //
  }, [listaAdmin]);

  const [errorModal, setErrorModal] = useState(false);
  const [varyingMessage, setVaryingMessage] = useState('');

  const [centredModal, setCentredModal] = useState(false);
  const [centredModal2, setCentredModal2] = useState(false);

  const [centredModal3, setCentredModal3] = useState(false);

  const [createGamerModal, setCreateGamerModal] = useState(false);

  const toggleGamerModal = () => {
    setCreateGamerModal(!createGamerModal);
  };
  const [modalExito, setModalExito] = useState(false);

  const [modalConfirmacion, setmodalConfirmacion] = useState(false);

  const [modalExitoEliminacion, setModalExitoEliminacion] = useState(false);

  const [filaSeleccionada, setFilaSeleccionada] = useState({
    userDNI: "",
    userFullName: "",
    userEmail: "",
    userPhone: "",
  });
  const toggleShow = (fila) => {
    setCentredModal(!centredModal);
    setFilaSeleccionada(fila);
  };
  const [filaSeleccionada2, setFilaSeleccionada2] = useState({
    gamerDNI: "",
    gamerName: "",
    gamerPhone: "",
    gamerEmail: "",
    gamerFullName: "",
    gamerAge: "",
    city_id: cityId,
  });
  const toggleShow3 = (fila) => {
    setFilaSeleccionada2({
      ...filaSeleccionada2,
      id: fila.id,
      gamerDNI: fila.gamerDNI,
      gamerName: fila.gamerName,
      gamerPhone: fila.gamerPhone,
      gamerEmail: fila.gamerEmail,
      gamerFullName: fila.gamerFullName,
      gamerAge: fila.gamerAge,
      city_id: fila.city_id,
    });
  };
  useEffect(() => {
  }, [filaSeleccionada2]);
  const toogleInsertar = () => {
    setCentredModal2(!centredModal2);
  };
  const [formData, setFormData] = useState({
    role_id: iconsActive,
    userDNI: "",
    userFullName: "",
    userEmail: "",
    userPhone: "",
    userPassword: "",
  });
  const handleDeleteUser = async (deletedUserId) => {
   if (iconsActive != 4) {
    const deleted = await userService.deleteUser(deletedUserId);
    if (deleted.status === 400) {
      togglemodalConfirmacion();
      setVaryingMessage(deleted.messages.error)
      setErrorModal(!errorModal)
    } else {
      await handleDataCrudTrigger();
      togglemodalConfirmacion();
      toggleModalExitoEliminacion();
    }
   }else{
    const deleted = await userService.deleteGamer(deletedUserId);
    if (deleted.status === 400) {
      togglemodalConfirmacion();
      setVaryingMessage(deleted.messages.error)
      setErrorModal(!errorModal)
    } else {
      await handleDataCrudTrigger();
      togglemodalConfirmacion();
      toggleModalExitoEliminacion();
    }
   }
  }
  useEffect(() => {
    if (usuarioEliminado) {

      handleDeleteUser(usuarioEliminado)
    }
  }, [usuarioEliminado]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      role_id: iconsActive,
    });
  };
  const [modalUpdated, setModalUpdated] = useState(false);
  const toggleUpdate = () => {
    setModalUpdated(!modalUpdated);
  };

  const updateAdmin = async (admin_id) => {
    const updatedAdmin = await userService.updateAdmin(
      admin_id,
      filaSeleccionada
    );
    if (updatedAdmin.status === 400) {
      setCentredModal(!centredModal)
      setVaryingMessage(updatedAdmin.messages.error)
      setErrorModal(!errorModal)
    } else {
      await handleDataCrudTrigger();
      setCentredModal(!centredModal);
      toggleUpdate();
    }
  };
  const updateGamer = async (gamer_id) => {
    const updatedGamer = await userService.updateGamer(
      gamer_id,
      filaSeleccionada2
    );

    if (updatedGamer.status === 400) {
      setVaryingMessage(updatedGamer.messages.error)
      setErrorModal(!errorModal)
    } else {
      await handleDataCrudTrigger();
      setCentredModal3(false);
      toggleShow3(
        {
          gamerDNI: "",
          gamerName: "",
          gamerPhone: "",
          gamerEmail: "",
          gamerFullName: "",
          gamerAge: "",
          city_id:"",
        }
      );
    }

  };
  const toggleModalExito = () => {
    setModalExito(!modalExito);
  };
  const togglemodalConfirmacion = (rowid = null) => {
    setmodalConfirmacion(!modalConfirmacion);

    if (rowid) {
      setUsuarioAEliminar(rowid);
    }
  };

  const togglemodalConfirmacion2 = async () => {
    setmodalConfirmacion(!modalConfirmacion);
  };
  const toggleModalExitoEliminacion = () => {
    setModalExitoEliminacion(!modalExitoEliminacion);
  };
  const createUser = async (e) => {

    e.preventDefault();
    const result = await userService.createAdmin(formData);

    if (result.status === 400) {
      setVaryingMessage(result.messages.error)
      setErrorModal(!errorModal)
    } else {
      await handleDataCrudTrigger();
      setCentredModal2(!centredModal2);
      toggleModalExito();
      setFormData({
         role_id: iconsActive,
    userDNI: "",
    userFullName: "",
    userEmail: "",
    userPhone: "",
    userPassword: "",
      })
    }

  };
  const [newGamer, setNewGamer] = useState({
    city_id: cityId,
    avatar_id: 1,
    gamerAge: "",
    gamerName: "",
    gamerFullName: "",
    gamerEmail: "",
    gamerDNI: "",
    gamerPhone: "",
    gamerPassword: "",
  });
  const handleGamerChange = (e) => {
    setNewGamer({
      ...newGamer,
      [e.target.name]: e.target.value,
    });
  };
  const createGamer = async (e) => {
    const gamer = JSON.parse(await userService.createGamer(newGamer));
    if (gamer.status === 400) {
      setVaryingMessage(gamer.messages.error)
      setErrorModal(!errorModal)
    }else{
    await handleDataCrudTrigger();
    toggleModalExito();
    toggleGamerModal();}
    setNewGamer({
       city_id: cityId,
    avatar_id: 1,
    gamerAge: "",
    gamerName: "",
    gamerFullName: "",
    gamerEmail: "",
    gamerDNI: "",
    gamerPhone: "",
    gamerPassword: "",
    })
    setRegionId(null)
    setCityId(null)
  };
  useEffect(()=>{
    if (filaSeleccionada2.id !='') {
      setFilaSeleccionada2({
        ...filaSeleccionada2,
        city_id:cityId
      })
    }else{
      setNewGamer({
        ...newGamer,
        city_id : cityId}
      )
    }
   
  },[cityId])

  return (
    <>
      <div className="page-admin d-flex flex-column min-vh-100">
        <MenuComponent onProfile={false} />
        <MDBContainer>
          <>
            <MDBRow className="d-flex align-items-center py-4">
              <MDBCol className="col-md-6 col-12">
                <h3 className="fw-bold">Administrador de usuarios</h3>
              </MDBCol>
              <MDBCol className="col-md-6 col-12 text-end">
                <div className="d-grid gap-2 col-12 d-md-block">
                  <MDBBtn
                    onClick={() => {
                      if (iconsActive != 4) {
                        toogleInsertar();
                      } else if (iconsActive === 4) {
                        toggleGamerModal();
                      }
                    }}
                    className="button-primary py-0 px-3"
                    outline
                    color="dark"
                  >
                    Crear usuario{" "}
                  </MDBBtn>
                  {/*
                  <CSVLink data={csvData} filename={"datos.csv"} className="shadow-0 ms-md-3">
                    <MDBBtn className="button-primary py-0 px-3" color="dark" outline>
                      Exportar a Excel (CSV)
                    </MDBBtn>
                  </CSVLink>
                  */}
                  <MDBBtn
                    onClick={() => {
                      let sheetData = [];
                      //set csvData without its first row
                      csvData.forEach((row, index) => {
                        if (index !== 0) {
                          sheetData.push(row);
                        }
                      });
                      let workbook = XLSX.utils.book_new();
                      let worksheet = XLSX.utils.json_to_sheet(sheetData);
                      let sheetName =
                        iconsActive === 1
                          ? "Administradores"
                          : iconsActive === 2
                          ? "Validador"
                          : iconsActive === 3
                          ? "Portero"
                          : "Jugador";

                      XLSX.utils.book_append_sheet(
                        workbook,
                        worksheet,
                        sheetName
                      );
                      XLSX.writeFile(workbook, "usuarios.xlsx", {
                        bookType: "xlsx",
                        type: "buffer",
                      });
                    }}
                    className="button-secondary py-0 px-3 ms-md-3"
                    outline
                    color="dark"
                  >
                    Exportar a Excel (XLSX)
                  </MDBBtn>
                  {/*
                  <MDBDropdown group className='shadow-0 ms-md-3'>
                    <MDBDropdownToggle className="py-0 px-3" color='dark' outline>Versión</MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem link>2023</MDBDropdownItem>
                      <MDBDropdownItem link>2022</MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                  {*/}
                </div>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBTabs className="mb-3">
                {roleData.map((rol) => (
                  <MDBTabsItem key={rol.id}>
                    <MDBTabsLink
                      key={rol.id}
                      value={rol.id}
                      onClick={() => handleIconsClick(rol.id)}
                      active={iconsActive == rol.id}
                    >
                      <MDBIcon fas icon="user-cog" className="me-2" />{" "}
                      {rol.roleName}
                    </MDBTabsLink>
                  </MDBTabsItem>
                ))}
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleIconsClick(4)}
                    active={iconsActive === 4}
                  >
                    <MDBIcon fas icon="gamepad" className="me-2" /> Jugador
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
              <MDBTabsContent>
                <MDBTabsPane className="text-right" show={iconsActive}>
                  <MDBDatatable
                    fixedHeader
                    search
                    searchLabel="Buscar..."
                    rowsText="Filas por página"
                    noFoundMessage="No se encontraron resultados"
                    data={listaAdmin}
                  />
                </MDBTabsPane>
              </MDBTabsContent>
            </MDBRow>
          </>
        </MDBContainer>
      </div>

      <>
        <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle className="fw-bold"> Editar {filaSeleccionada.role} </MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleShow}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBInput
                  className="mb-3"
                  label="Rut"
                  value={filaSeleccionada.userDNI}
                  onChange={(e) =>
                    setFilaSeleccionada({
                      ...filaSeleccionada,
                      userDNI: e.target.value,
                    })
                  }
                ></MDBInput>
                <MDBInput
                  className="mb-3"
                  label="Nombre Completo"
                  value={filaSeleccionada.userFullName}
                  onChange={(e) =>
                    setFilaSeleccionada({
                      ...filaSeleccionada,
                      userFullName: e.target.value,
                    })
                  }
                />
                <MDBInput
                  className="mb-3"
                  label="Email"
                  value={filaSeleccionada.userEmail}
                  onChange={(e) =>
                    setFilaSeleccionada({
                      ...filaSeleccionada,
                      userEmail: e.target.value,
                    })
                  }
                />

                <MDBInput
                  className="mb-3"
                  label="Teléfono"
                  value={filaSeleccionada.userPhone}
                  onChange={(e) =>
                    setFilaSeleccionada({
                      ...filaSeleccionada,
                      userPhone: e.target.value,
                    })
                  }
                />

                <MDBInput
                  type="password"
                  className="mb-3 mt-3"
                  label="(Editar contraseña actual)"
                  value={filaSeleccionada.userPassword}
                  onChange={(e) =>
                    setFilaSeleccionada({
                      ...filaSeleccionada,
                      userPassword: e.target.value,
                    })
                  }
                ></MDBInput>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  onClick={(e) => updateAdmin(filaSeleccionada.id)}
                  className="button-primary px-4 py-0"
                  outline
                  color="dark"
                >
                  Guardar cambios
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <MDBModal tabIndex="-1" show={centredModal2} setShow={setCentredModal2}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Agregar Usuario </MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toogleInsertar}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBInput
                  className="mb-3"
                  minLength="20"
                  label="Rut"
                  name="userDNI"
                  value={formData?.userDNI}
                  onChange={handleChange}
                />
                <MDBInput
                  className="mb-3"
                  label="Nombre"
                  name="userFullName"
                  value={formData?.userFullName}
                  onChange={handleChange}
                />

                <MDBInput
                  className="mb-3"
                  label="Email"
                  name="userEmail"
                  value={formData?.userEmail}
                  onChange={handleChange}
                />

                <MDBInput
                  label="Teléfono"
                  name="userPhone"
                  value={formData?.userPhone}
                  onChange={handleChange}
                  placeholder="912345678"
                />

                <MDBInput
                  className="mb-3 mt-3"
                  label="Contraseña"
                  type="password"
                  name="userPassword"
                  value={formData?.userPassword}
                  onChange={handleChange}
                />
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  onClick={createUser}
                  className="button-primary py-0 px-3"
                  outline
                  color="dark"
                >
                  Agregar usuario
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <MDBModal
          tabIndex="-1"
          show={createGamerModal}
          setShow={setCreateGamerModal}
        >
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Agregar Jugador </MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleGamerModal}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBInput
                  className="mb-3"
                  minLength="20"
                  label="Nombre de Usuario"
                  name="gamerName"
                  value={newGamer?.gamerName}
                  onChange={handleGamerChange}
                />
                <MDBInput
                  className="mb-3"
                  minLength="20"
                  label="Nombre completo"
                  name="gamerFullName"
                  value={newGamer?.gamerFullName}
                  onChange={handleGamerChange}
                />
                <MDBInput
                  className="mb-3"
                  minLength="20"
                  label="Rut"
                  name="gamerDNI"
                  value={newGamer?.gamerDNI}
                  onChange={handleGamerChange}
                />
                <MDBInput
                  className="mb-3"
                  label="Email"
                  name="gamerEmail"
                  type="email"
                  value={newGamer?.gamerEmail}
                  onChange={handleGamerChange}
                />

                <MDBInput
                  className="mb-3"
                  label="Teléfono"
                  name="gamerPhone"
                  value={newGamer?.gamerPhone}
                  placeholder="912345678"
                  onChange={handleGamerChange}
                />
                <MDBInput
                  className="mb-3 mt-3"
                  minLength="20"
                  label="Edad"
                  name="gamerAge"
                  value={newGamer?.gamerAge}
                  onChange={handleGamerChange}
                />
                 {
                                    region.length > 0
                                    &&
                                    <MDBSelect
                                        className="mb-3"
                                        onValueChange={(e) => setRegionId(e.value)}
                                        preventFirstSelection
                                        placeholder='Seleccione una región'
                                        data={
                                            region.map(
                                                region =>
                                                (
                                                    {
                                                        text: region.stateName,
                                                        value: region.id,
                                                        defaultSelected: region.id == regionId
                                                    }
                                                )
                                            )
                                        }
                                    />
                                }


                                {
                                    regionId && <MDBSelect
                                        className="mb-3"
                                        onValueChange={(e) => setCityId(e.value)}
                                        label='Ciudad o comuna'
                                        data={city.map(city => (
                                            {
                                                text: city.cityName,
                                                value: city.id,
                                                defaultSelected: city.id == cityId
                                            }
                                        ))}
                                    />
                                }

                <MDBInput
                  className="mb-3"
                  label="(Crear contraseña)"
                  type="password"
                  name="gamerPassword"
                  value={newGamer?.gamerPassword}
                  onChange={handleGamerChange}
                />
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  onClick={createGamer}
                  className="button-primary"
                  outline
                  color="dark"
                >
                  Agregar usuario
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <MDBModal tabIndex="-1" show={centredModal3} setShow={setCentredModal3}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle className="fw-bold">Editar Jugador</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={() => setCentredModal3(false)}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBInput
                  className="mb-3"
                  label="Nombre de Usuario"
                  disabled
                  value={filaSeleccionada2.gamerName}
                  onChange={(e) =>
                    setFilaSeleccionada2({
                      ...filaSeleccionada2,
                      gamerName: e.target.value,
                    })
                  }
                />

                <MDBInput
                  className="mb-3"
                  label="Nombre completo"
                  value={filaSeleccionada2.gamerFullName}
                  onChange={(e) =>
                    setFilaSeleccionada2({
                      ...filaSeleccionada2,
                      gamerFullName: e.target.value,
                    })
                  }
                />

                <MDBInput
                  className="mb-3"
                  label="Edad"
                  value={filaSeleccionada2.gamerAge}
                  type="number"
                  onChange={(e) =>
                    setFilaSeleccionada2({
                      ...filaSeleccionada2,
                      gamerAge: e.target.value,
                    })
                  }
                />

                <MDBInput
                  className="mb-3"
                  label="Rut"
                  value={filaSeleccionada2.gamerDNI}
                  onChange={(e) =>
                    setFilaSeleccionada2({
                      ...filaSeleccionada2,
                      gamerDNI: e.target.value,
                    })
                  }
                />

                <MDBInput
                  className="mb-3"
                  label="Email"
                  value={filaSeleccionada2.gamerEmail}
                  onChange={(e) =>
                    setFilaSeleccionada2({
                      ...filaSeleccionada2,
                      gamerEmail: e.target.value,
                    })
                  }
                />

                <MDBInput
                  className="mb-3"
                  label="Teléfono"
                  value={filaSeleccionada2.gamerPhone}
                  onChange={(e) =>
                    setFilaSeleccionada2({
                      ...filaSeleccionada2,
                      gamerPhone: e,
                    })
                  }
                  placeholder="912345678"
                />
                {region.length > 0 && (
                  <MDBSelect
                    className="mb-3"
                    onValueChange={(e) => setRegionId(e.value)}
                    preventFirstSelection
                    placeholder="Seleccione una región"
                    data={region.map((region) => ({
                      text: region.stateName,
                      value: region.id,
                      defaultSelected: region.id == regionId,
                    }))}
                  />
                )}
                {regionId && (
                  <MDBSelect
                    className="mb-3"
                    onValueChange={(e) => setCityId(e.value)}
                    label="Ciudad o comuna"
                    data={city.map((city) => ({
                      text: city.cityName,
                      value: city.id,
                      defaultSelected: city.id == filaSeleccionada2.city_id,
                    }))}
                  />
                )}

                <MDBInput
                  className="mb-3 mt-3"
                  type="password"
                  label="Contraseña"
                  value={filaSeleccionada2.gamerPassword}
                  onChange={(e) =>
                    setFilaSeleccionada2({
                      ...filaSeleccionada2,
                      gamerPassword: e.target.value,
                    })
                  }
                />
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  onClick={async (e) => {
                    updateGamer(filaSeleccionada2.id);
                  }}
                  className="button-primary px-4 py-0"
                  outline
                  color="dark"
                >
                  Guardar cambios
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <MDBModal staticBackdrop tabIndex="-1" show={modalExito} setShow={setModalExito}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle className="fw-bold">Exito! </MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleModalExito}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p className="fs-6 fw-normal">Usuario Creado!</p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  onClick={toggleModalExito}
                  className="button-primary px-4 py-0"
                  outline
                  color="dark"
                >
                  Aceptar
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <MDBModal
          tabIndex="-1"
          show={modalConfirmacion}
          setShow={setmodalConfirmacion}
        >
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Eliminar Usuario </MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={togglemodalConfirmacion}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p className="fs-6 fw-normal">
                  ¿Está seguro que desea eliminar este usuario?
                </p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  className="button-primary px-4 py-0"
                  outline
                  color="dark"
                  onClick={() => setUsuarioEliminado(usuarioAEliminar)}
                >
                  Aceptar
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <MDBModal
          tabIndex="-1"
          show={modalExitoEliminacion}
          setShow={setModalExitoEliminacion}
        >
          <MDBModalDialog staticBackdrop centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle className="fw-bold">Exito! </MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleModalExitoEliminacion}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p className="fs-6 fw-normal">Usuario Eliminado</p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  className="button-primary px-4 py-0"
                  outline
                  color="dark"
                  onClick={toggleModalExitoEliminacion}
                >
                  Aceptar
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <MDBModal staticBackdrop tabIndex="-1" show={modalUpdated} setShow={setModalUpdated}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle className="fw-bold">Exito!</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleUpdate}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p className="fs-6 fw-normal">Datos actualizados</p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  onClick={toggleUpdate}
                  className="button-primary px-4 py-0"
                  outline
                  color="dark"
                >
                  Aceptar
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <MDBModal show={errorModal} setShow={setErrorModal} tabIndex="-1">
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle className="fw-bold">Error</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={() => setErrorModal(!errorModal)}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className="mb-3">{errorModal && varyingMessage}</div>
              </MDBModalBody>
              <MDBModalFooter></MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    </>
  );
};
export default UserAdmin;
