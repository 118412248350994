const importXLSX = async (id, formData) => {
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'manual',
    }
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/challenges/import-xlsx/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data;
}

export default {
    importXLSX,
};