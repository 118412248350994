import React, { useEffect, useState } from "react";
import MenuComponent from "../MenuComponent";
import {
    MDBCard,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBCardBody,
    MDBBtn,
    MDBAccordion,
    MDBAccordionItem
} from "mdb-react-ui-kit";
import zoneService from "../../services/zones";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";


const VersionChallengeCodesAdmin = () => {

    const [accordionState, setAccordionState] = useState({});

    const navigate = useNavigate();
    const { version_id } = useParams();

    const [zones, setZones] = useState([]);
    const [state, setState] = useState(localStorage.getItem('token'));

    const [user, setUser] = useState({
        id: null,
        userFullName: "",
        userEmail: "",
        userPassword: "",
    });

    const onImageCownload = (svgDOM) => {
        const svg = svgDOM;
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };

    useEffect(() => {

        if (!state) {
            navigate('/login');
        } else {
            const newUser = JSON.parse(atob(JSON.parse(state).user))
            setUser(newUser);
        }

    }, []);

    const handleDataLoad = async () => {
        const result = await zoneService.getZonesByVersion(version_id);
        setZones(result);
    }

    useEffect(() => {
        if (user.id != null) {
            handleDataLoad();
        }
    }, [user]);

    return (
        <>
            <div className="page-cubes d-flex flex-column min-vh-100">

                <MenuComponent onProfile={false} />
                <MDBContainer className="my-5">
                    {
                        zones.map((zone, index) => (
                            <MDBRow key={index}>
                                <MDBCol key={index} className="g-4 mt-0 mx-auto" style={
                                    {
                                        maxWidth: "640px",
                                    }
                                }>
                                    <h3 className="fw-bold mt-4">{zone.zoneName}</h3>
                                    <MDBAccordion initialActive={1} className="faq my-4">
                                        {
                                            zone.challenges.map((challenge, challengeIndex) => {
                                                let code = JSON.stringify({
                                                    type: "challenge",
                                                    salt: process.env.REACT_APP_SALT,
                                                    zone_id: zone.id,
                                                    challenge_id: challenge.id,
                                                    challengeName: challenge.challengeName,
                                                });
                                                code = btoa(code);
                                                return (
                                                    <MDBAccordionItem
                                                        key={challengeIndex}
                                                        collapseId={`${index}-${challengeIndex}`}
                                                        headerTitle={`${challenge.challengeActivity} - ${challenge.challengeDescription}`}
                                                    >

                                                        <p className="fs-5 mb-1">
                                                            Dificultad: {challenge.challengeDifficulty}
                                                        </p>
                                                        <p className="fs-5 mb-4">
                                                            Cristales: {challenge.challengeScore}
                                                        </p>
                                                        <QRCode
                                                            id={`svg-${challenge.id}`}
                                                            value={code}
                                                            style={
                                                                {
                                                                    margin: "auto",
                                                                    maxWidth: "100%",
                                                                    width: "100%",
                                                                }
                                                            }
                                                        />
                                                        <MDBBtn
                                                            className="w-100 button-primary mt-4"
                                                            color="dark"
                                                            outline
                                                            onClick={
                                                                () => {
                                                                    const svg = document.getElementById(`svg-${challenge.id}`);
                                                                    onImageCownload(svg);
                                                                }
                                                            }
                                                        >
                                                            Descargar
                                                        </MDBBtn>

                                                    </MDBAccordionItem>
                                                )
                                            })
                                        }
                                    </MDBAccordion>
                                </MDBCol>
                            </MDBRow>
                        ))
                    }
                </MDBContainer>
            </div>
        </>
    )
}

export default VersionChallengeCodesAdmin