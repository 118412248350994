import React, { useState, useEffect, useCallback } from "react";
import versionService from "../../services/versions";
import braceletsService from "../../services/bracelets";
import stateService from "../../services/states";
import cityService from "../../services/cities";

import userService from "../../services/users";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBCardText,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBDatepicker,
  MDBBadge,
  MDBDatatable,
  MDBPopover,
  MDBPopoverBody,

  MDBPopconfirm,
  MDBPopconfirmMessage,
} from "mdb-react-ui-kit";
import MenuComponent from "../MenuComponent";
import { useNavigate, useParams } from "react-router-dom";

const BraceletAdmin = () => {
  const [braceletReader, setBraceletReader] = useState(null);
  const navigate = useNavigate();
  const token = JSON.parse(window.localStorage.getItem("token"));
  if (token.status != 200 || !token) {
    navigate('/login');
  }
  const [bracelets, setBracelets] = useState([]);
  const [versionId, setVersionId] = useState(null);
  const [selectedBraceletId, setSelectedBraceletId] = useState(null)

  const [currentBracelet, setCurrentBracelet] = useState({
    id: "",
    braceletSerialNumber: "",
    braceletGamerName: "-",
    braceletGamerDNI: "-"
  })
  const [editBraceletModal, setEditBraceletModal] = useState();
  const toggleBraceletEditModal = () => {
    setEditBraceletModal(!editBraceletModal)
    handleGamersDataLoad()
  };
  const [newGamerId, setNewGamerId] = useState(null);

  const [isMobile, setIsMobile] = useState(false);
  const [braceletData, setBraceletData] = useState({
    columns: [],
    rows: [],
  });
  const version = useParams();


  const [hasName, setHasName] = useState(null);

  const [gamers, setGamers] = useState([]);
  // modal de nueva pulsera

  const [modalExito, setModalExito] = useState(false);

  const toggleModalExito = () => setModalExito(!modalExito);

  const [modalError, setModalError] = useState(false);

  const toggleModalError = () => setModalError(!modalError);

  const [braceletModal, setBraceletModal] = useState(false);

  const toggleBraceletModal = () => setBraceletModal(!braceletModal);

  const [newBracelet, setNewBracelet] = useState({
    version_id: version.id,
    braceletSerialNumber: ""
  });
  // carga de pulseras
  const handleBraceletDataLoad = async () => {
    if (versionId) {
      const newBraceletsByVersion =
        await braceletsService.getBraceletsInVersion(versionId);

      const braceletByVersion = await Promise.all(
        newBraceletsByVersion.map(async (newBracelet) => {
          const gamer = await getGamerByBraceletId(newBracelet.id);
          return {
            id: newBracelet.id,
            braceletSerialNumber: newBracelet.braceletSerialNumber,
            gamer: gamer,
          };
        })
      );

      setBracelets(braceletByVersion);
    }
  };
  // carga de gamers segun pulseras
  const getGamerByBraceletId = async (braceletId) => {
    const newGamer = await braceletsService.getGamerByBraceletId(braceletId);
    if (newGamer.length <= 0) {

      const unassignedGamer = {
        gamerDNI: "-",
        gamerName: "-",
      };
      return unassignedGamer;

    } else {
      if (newGamer[0].gamer) {
        const newGamerDNI = newGamer[0].gamer.gamerDNI;

        let lastCharacter = newGamerDNI.slice(-1);
        //get first 8 characters
        let firstEightCharacters = newGamerDNI.slice(0, -1);
        //set firstEightCharacters as a number separated by dots (thousands)
        let firstEightCharactersDots = firstEightCharacters.replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          "$1."
        );
        //concatenate firstEightCharactersDots and lastCharacter
        let gamerDNIFormatted = firstEightCharactersDots + "-" + lastCharacter;
        const finalGamerDNI = gamerDNIFormatted;
        const gamer = {
          id: newGamer[0].id,
          gamerDNI: finalGamerDNI,
          gamerName: newGamer[0].gamer.gamerName,
        }
        return gamer;
      };

    }
  };
  // carga de tabla
  const handleBraceletCrud = async () => {
    if (bracelets.length > 0) {
      const rows = bracelets.map((bracelet) => ({
        id: bracelet.id,
        braceletSerialNumber: bracelet.braceletSerialNumber,
        braceletGamerName: bracelet.gamer.gamerName,
        braceletGamerDNI: bracelet.gamer.gamerDNI,
      }));
      const columns = [
        {
          width: 150,
          label: "N°",
          field: "id",
        },
        {
          width: 150,
          label: "Numero de serie",
          field: "braceletSerialNumber",
        },
        {
          width: 150,
          label: "Jugador Asignado",
          field: "braceletGamerName",
        },
        {
          width: 150,
          label: "Rut",
          field: "braceletGamerDNI",
        },

        { label: "Acciones", field: "action", sort: false },
      ];
      setBraceletData({
        columns: columns,
        rows: rows.map((row) => {
          return {
            ...row,
            action: (
              <>
                <MDBBtn color="light" rippleColor="dark" onClick={() => {
                  setCurrentBracelet(row);
                  toggleBraceletEditModal();
                  if (row.braceletGamerName != '-') {
                    setHasName(1);
                  }
                }}>
                  <MDBIcon fas icon="edit" />
                </MDBBtn>
              { row.braceletGamerName == '-' &&  <MDBPopconfirm
                  cancelBtnText='Cancelar'
                  confirmBtnText='Eliminar'
                  color="light"
                  rippleColor="dark"
                  modal
                  confirmBtnClasses='button-primary'
                  cancelBtnClasses='button-light'
                  btnChildren={<MDBIcon fas icon="trash-alt" />}
                  onConfirm={() => { setSelectedBraceletId(row.id) }}
                >
                  <MDBPopconfirmMessage><p className="fs-5 mb-4 p-4">¿Eliminar este registro?</p></MDBPopconfirmMessage>
                </MDBPopconfirm>}
              </>
            ),
          };
        }),
      });
    }
  };
  const handleGamersDataLoad = async () => {
    const newGamers = await userService.getGamers();
    if (newGamers.status != 404) {
      const gamer = newGamers.map((gam) => ({
        id: gam.id,
        gamerName: gam.gamerName,
        gamerDNI: gam.gamerDNI
      }))
      setGamers(gamer)

    }
  }


  const handleNewBraceletChange = (e) => {
    setNewBracelet({
      ...newBracelet,
      [e.target.name]: e.target.value,
    });
  }
  const handleBraceletCreation = async (e) => {

    e.preventDefault();
    const checkBraceletAlreadyExists = await braceletsService.getBraceletByBraceletSerialNumber(newBracelet);
    const exists = checkBraceletAlreadyExists.id ? true:false ;

    if (!exists) {
      const response = await braceletsService.createBracelet(newBracelet);
      if (response.status === 400) {
        alert("error", response);
      } else {
        toggleBraceletModal();

        toggleModalExito();
        handleBraceletDataLoad();
      }
    }else{
      
      toggleBraceletModal();

      toggleModalError();
    }
    
  }
  const handleBraceletDeletion = async (selectedBraceletId) => {
    const response = await braceletsService.deleteBracelet(selectedBraceletId);
    if (response.status === 400) {
      console.log('error', response);
    } else {

      toggleModalExito();
      handleBraceletDataLoad()
    }
  }

  const handleGamerBraceletDeletion = async (id_bracelet) => {
    const gamerBracelet = await getGamerByBraceletId(id_bracelet);
    const data = await braceletsService.deleteGamerBracelet(gamerBracelet.id)
    if (data) {
      handleBraceletDataLoad()
      setHasName(null)
      toggleBraceletEditModal()

      toggleModalExito();

    }

  }

  const [newGamerBracelet, setNewGamerBracelet] = useState({
    bracelet_id: currentBracelet.id,
    gamer_id: newGamerId,
  })

  const handleGamerBraceletCreation = async (e) => {
    e.preventDefault();
    console.log(newGamerBracelet)
    if (newGamerId) {
      const newGamerBraceletFromService = await braceletsService.createGamerBracelet(newGamerBracelet)
      if (newGamerBraceletFromService.status != 400) {

        handleBraceletDataLoad()
        setNewGamerId(null)
        toggleBraceletEditModal()
        toggleModalExito();
      }
    }
  }

  useEffect(() => {
    setNewGamerBracelet({
      ...newGamerBracelet,
      bracelet_id: currentBracelet.id,

    })
  }, [currentBracelet])

  useEffect(() => {
    setNewGamerBracelet({
      ...newGamerBracelet,
      gamer_id: newGamerId,

    })
  }, [newGamerId])

  //en caso de que la vista sea desde smartphone, se muestra el boton de scan pulseras
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Establece el estado como verdadero si el ancho es menor o igual a 768px 
    };
    window.addEventListener("resize", handleResize);

    // Llama a handleResize inicialmente para establecer el estado en la carga inicial del componente
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (version) {
      setVersionId(version.id);
    }
  }, []);

  useEffect(() => {
    if (selectedBraceletId) {
      handleBraceletDeletion(selectedBraceletId)
    }
  }, [selectedBraceletId])
  useEffect(() => {
    if (versionId) {
      handleBraceletDataLoad();
    }
  }, [versionId]);
  useEffect(() => {
    handleBraceletCrud();
  }, [bracelets]);

  const handleNFCReader = async () => {

    try {
      const reader = new NDEFReader();
      await reader.scan();
      reader.onreading = async (event) => {
        //setBraceletData(event.serialNumber);
        setNewBracelet({
          ...newBracelet,
          braceletSerialNumber: event.serialNumber,
        });
      }
      reader.onreadingerror = () => {
        alert("Error de lectura NFC");
      }
    } catch (error) {
      alert(error)
    }

  }  

  useEffect(() => {
    if (braceletModal) {
      handleNFCReader();
    } else {
      setNewBracelet({
        ...newBracelet,
        braceletSerialNumber: '',
      });
      setBraceletReader(null);
    }
  }, [braceletModal]);
  
  return (
    <div className="page-admin d-flex flex-column min-vh-100">
      <MenuComponent onProfile={false} />
      <MDBContainer className="py-5">
        <>
          <MDBContainer className="px-4 justify-content-center text-left ">
            <MDBRow className="d-flex align-items-center py-4">
              <MDBCol className="col-md-6 col-12">
                <h3 className="fw-bold">Administrador de Pulseras</h3>
              </MDBCol>
              <MDBCol className="col-md-6 col-12 text-end">
                <div className="d-grid gap-2 col-12 d-md-block">
                  {isMobile && (
                    <MDBBtn
                      size="lg"
                      outline
                      color="dark"
                      className="button-pink"
                      onClick={toggleBraceletModal}
                    >
                      Escanear Pulsera
                    </MDBBtn>
                  )}

                  {/*}
                  <MDBDropdown group className="shadow-0 ms-md-3">
                    <MDBDropdownToggle
                      className="py-0 px-3"
                      color="dark"
                      outline
                    >
                      Año
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem link>2023</MDBDropdownItem>
                      <MDBDropdownItem link>2022</MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                  */}
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className="d-flex align-items-center py-4">
              <MDBCol className="col-md-12 col-12">
                <div style={{ overflowX: "auto" }}>
                  <MDBDatatable
                    fixedHeader
                    search
                    searchLabel="Buscar..."
                    rowsText="Filas por página"
                    data={braceletData}
                    noFoundMessage="No se encontraron resultados"
                  />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBModal tabIndex='-1' show={braceletModal} setShow={setBraceletModal}>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Scanear Pulsera</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={toggleBraceletModal}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBInput
                    className="mb-3"
                    minLength="20"
                    label="Scanee el codigo..."
                    name="braceletSerialNumber"
                    value={newBracelet.braceletSerialNumber}
                    onChange={handleNewBraceletChange}
                    disabled
                  />
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn outline color="dark" onClick={handleBraceletCreation}>
                    Guardar
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          <MDBModal show={editBraceletModal} staticBackdrop setShow={setEditBraceletModal}>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Editar Pulsera</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={() => {
                    toggleBraceletEditModal()
                    setHasName(null)
                  }}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBInput
                    disabled
                    className="mb-3"
                    minLength="20"
                    label="Codigo pulsera"
                    name="braceletSerialNumber"
                    value={currentBracelet.braceletSerialNumber}
                    onChange={handleNewBraceletChange}
                  />
                  {editBraceletModal && gamers && !hasName && (
                    <MDBSelect
                      search

                      className="mb-3"
                      onValueChange={(e) => setNewGamerId(e.value)}
                      data={gamers.map(

                        gamr => ({
                          text: gamr.gamerName + ' ' + gamr.gamerDNI,
                          value: gamr.id,
                          defaultSelected: gamr.id == newGamerId
                        })
                      )}
                    />
                  )}
                  {editBraceletModal && hasName && (
                    <>
                      <MDBInput
                        disabled
                        className="mb-3"
                        minLength="20"
                        label="Nombre Gamer"
                        name="braceletGamerName"
                        value={currentBracelet.braceletGamerName}
                        onChange={handleNewBraceletChange}
                      />
                      <MDBInput
                        disabled
                        className="mb-3"
                        minLength="20"
                        label="DNI Gamer"
                        name="braceletGamerName"
                        value={currentBracelet.braceletGamerDNI}
                        onChange={handleNewBraceletChange}
                      />
                    </>
                  )}
                </MDBModalBody>
                <MDBModalFooter>
                  
                  {editBraceletModal && hasName && (
                    <MDBBtn outline color="dark" className="button-primary" onClick={() => { handleGamerBraceletDeletion(currentBracelet.id) }}>
                      Desligar Jugador
                    </MDBBtn>
                  )}
                  {editBraceletModal && gamers && !hasName && (
                    <MDBBtn outline color="dark" className="button-primary" onClick={handleGamerBraceletCreation}>
                      Asignar a Jugador
                    </MDBBtn>
                  )}
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          <MDBModal staticBackdrop tabIndex='-1' show={modalExito} setShow={setModalExito}>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Exito!</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={toggleModalExito}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <p>
                    Datos guardados!
                  </p>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color='secondary' onClick={toggleModalExito}>
                    Aceptar
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          <MDBModal staticBackdrop tabIndex='-1' show={modalError} setShow={setModalError}>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Exito!</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={toggleModalError}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <p>
                    Esta pulsera ya se encuentra registrada!
                  </p>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color='secondary' onClick={toggleModalError}>
                    Aceptar
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </>
      </MDBContainer>
    </div>
  );
};
export default BraceletAdmin;
