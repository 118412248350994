import React, { useEffect, useState } from 'react'
import versionService from "../../services/versions";
import stateService from "../../services/states";
import cityService from "../../services/cities";
import roleService from "../../services/roles";
import notificationService from "../../services/notifications";
import {
  MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon, MDBCardText, MDBBtn, MDBDropdown, MDBDropdownToggle,
  MDBDropdownMenu, MDBDropdownItem, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle,
  MDBModalBody, MDBModalFooter, MDBInput, MDBSelect, MDBDatepicker, MDBBadge, MDBDatatable, MDBPopover, MDBPopoverBody, MDBTextArea
} from "mdb-react-ui-kit";
import MenuComponent from '../MenuComponent';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

const NotificationManagerAdmin = () => {

  const [centredModal, setCentredModal] = useState(false);
  const [modalBorrado, setModalBorrado] = useState(false);

  const [entryId, setEntryId] = useState(null);
  const [currentVersion, setCurrentVersion] = useState({
    version_id: null,
    city_id: "",
    versionName: "",
    versionAnnouncementDate: "",
    versionOpeningDate: "",
    versionClosingDate: "",
  });
  const toggleShow = () => setCentredModal(!centredModal);

  const [modalExito, setModalExito] = useState(false);
  const [modalVista, setModalVista] = useState(false);

  const toggleExito = () => setModalExito(!modalExito);
  const toggleVista = () => setModalVista(!modalVista);

  const toggleBorrado = () => {
    if (modalBorrado) {
      setEntryId(null);
    }
    setModalBorrado(!modalBorrado);
  };

  const [notificationDataTable, setNotificationDataTable] = useState({
    columns: [
      {
        label: 'Título',
        field: 'notificationTitle',
        width: 150,
      },
      {
        label: 'Mensaje',
        field: 'notificationMessage',
        width: 270,
      },
      {
        label: 'Objetivos',
        field: 'notificationReference',

      },
      {
        label: 'Fecha',
        field: 'created_at',
        width: 200,
      },
      {
        label: 'Acciones',
        field: 'actions',
      }
    ],
    rows: []
  })

  const notificationConditionTypeOptions = [
    {
      text: 'Manual',
      value: 'manual',
    }
  ]

  const notificationReferenceOptions = [
    {
      text: 'Administradores',
      value: 'administradores',
      hidden: false
    },
    {
      text: 'Validadores en un Evento',
      value: 'validadores_evento',
      hidden: false
    },
    {
      text: 'Porteros en un Evento',
      value: 'porteros_evento',
      hidden: false
    },
    {
      text: 'Jugadores en un Evento',
      value: 'jugadores_evento',
      hidden: false
    },
    {
      text: 'Jugador particular',
      value: 'jugador_particular',
      hidden: true
    }
    /*
    {
      text: 'Validadores en un Evento (Email)',
      value: 'validadores_evento',
    },
    {
      text: 'Porteros en un Evento (Email)',
      value: 'porteros_evento',
    },
    {
      text: 'Jugadores en un Evento (Email)',
      value: 'jugadores_evento',
    }
    */
  ]

  const [formData, setFormData] = useState({
    notificationTitle: "",
    notificationMessage: "",
    notificationConditionType: "manual",
    notificationReference: "validadores_evento",
    notificationStatus: "activa"
  })

  const handleDataDelete = async () => {
    try {
      const result = await notificationService.remove(entryId);
      handleDataLoad();
      toggleBorrado();
    } catch (error) {
      console.log(error);
    }
  }

  const handleFormDataSync = async () => {
    try {
      const result = await notificationService.get(entryId);
      setFormData({
        ...formData,
        notificationTitle: result.notificationTitle,
        notificationMessage: result.notificationMessage,
        notificationConditionType: result.notificationConditionType,
        notificationReference: result.notificationReference,
        notificationStatus: result.notificationStatus
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (entryId !== null) {
      handleFormDataSync();
    }
  }, [entryId])

  const handleDataLoad = async () => {
    try {
      const thisVersion = await versionService.getVersionCurrent();
      setCurrentVersion(thisVersion);

      const result = await notificationService.getAll();
      console.log(result)
      const rows = result
        .filter(
          (item) => {
            return notificationReferenceOptions.find((element) => element.value === item.notificationReference).hidden === false;
          }
        )
        .map((item) => {
          //remove 4 hours from date using momentjs
          let date = moment(item.created_at.date).subtract(4, 'hours').format('DD-MM-YYYY HH:mm:ss');
          return {
            notificationTitle: item.notificationTitle,
            notificationMessage: item.notificationMessage,
            created_at: date,
            notificationReference: notificationReferenceOptions.find((element) => element.value === item.notificationReference).text,
            actions: <div className="d-flex justify-content-center">
              <MDBBtn
                color='dark'
                outline
                className='me-2 button-secondary py-0 px-3'
                onClick={
                  () => {
                    setEntryId(item.id);
                    toggleVista();
                  }
                }>
                <MDBIcon fas icon="eye" />
              </MDBBtn>
              <MDBBtn
                color='dark'
                outline
                className='button-primary py-0 px-3'
                onClick={
                  () => {
                    setEntryId(item.id);
                    toggleBorrado();
                  }
                }>
                <MDBIcon fas icon="trash" />
              </MDBBtn>
            </div>
          }
        })

      setNotificationDataTable({ ...notificationDataTable, rows: rows })

    } catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {
    //console.log(currentVersion)
  }, [currentVersion])

  const handleFieldChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  const handleDataCreate = async () => {
    try {
      if (currentVersion.id === null) {
        alert("No hay una versión actual activa, por favor cree una versión")
        return;
      }

      let newFormData = {
        ...formData,
        version_id: currentVersion.id
      };

      const result = await notificationService.create(newFormData);
      handleDataLoad();
      toggleShow();
      toggleExito();

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleDataLoad();
  }, [])

  return (<div className="page-admin d-flex flex-column min-vh-100">
    <MenuComponent onProfile={false} />
    <MDBContainer className="py-5">
      <>
        <MDBContainer className="px-4 justify-content-center text-left ">
          <MDBRow className="d-flex align-items-center py-4">
            <MDBCol className="col-md-6 col-12">
              <h3 className="fw-bold">Administrador de Notificaciones</h3>
            </MDBCol>
            <MDBCol className="col-md-6 col-12 text-end">
              <div className="d-grid gap-2 col-12 d-md-block">
                <MDBBtn
                  className="button-primary py-0 px-3"
                  size='lg'
                  outline
                  color="dark"
                  onClick={toggleShow}
                >
                  Nueva notificación
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="d-flex align-items-center py-4">
            <MDBCol className="col-md-12 col-12">
              <MDBDatatable
                fixedHeader
                search
                searchLabel="Buscar..."
                rowsText="Filas por página"
                noFoundMessage="No se encontraron resultados"
                data={notificationDataTable}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
          <MDBModalDialog centered size='xl'>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle className='fw-bold'>Crear nueva notificación</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <form>

                  <input type="hidden"
                    name="notificationConditionType"
                    value={'manual'}
                  />

                  <MDBSelect
                    label='Objetivo'
                    className='mb-3'
                    name='notificationReference'
                    data={
                      notificationReferenceOptions
                        .filter((item) => {
                          return item.hidden === false;
                        })
                        .map((item) => {
                        return {
                          text: item.text,
                          value: item.value,
                          defaultSelected: item.value === formData.notificationReference
                        }
                      })
                    }
                    onValueChange={
                      (e) => {
                        setFormData({
                          ...formData,
                          notificationReference: e.value
                        })
                      }
                    }
                  />

                  <MDBInput
                    label='Título'
                    className='form-control mb-3'
                    name='notificationTitle'
                    maxLength={50}
                    onChange={handleFieldChange}
                  />

                  <MDBTextArea
                    label='Mensaje'
                    rows={2}
                    className='form-control mb-3'
                    name='notificationMessage'
                    maxLength={150}
                    onChange={handleFieldChange}
                  />

                </form>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  onClick={toggleShow}
                  outline
                  color="dark"
                  className='button-outline py-0 px-3'
                >
                  Cancelar
                </MDBBtn>
                {
                  formData.notificationTitle.length > 0 &&
                  formData.notificationMessage.length > 0 &&
                  <>
                    <MDBBtn
                      onClick={handleDataCreate}
                      outline
                      color='dark'
                      className='button-primary py-0 px-3'
                    >
                      Enviar
                    </MDBBtn>
                  </>
                }
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal staticBackdrop tabIndex='-1' show={modalExito} setShow={setModalExito}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle className='fw-bold'>Notficación enviada</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleExito}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p className='fs-6'>
                  El mensaje ha sido enviado.
                </p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn className='button-primary fs-6 fw-bold' onClick={toggleExito}>
                  aceptar
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal tabIndex='-1' show={modalBorrado} setShow={setModalBorrado}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle className='fw-bold'>¡Alerta!</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleBorrado}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p>
                  ¿Está seguro que desea eliminar la notificación?
                </p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color='dark' className='button-secondary px-3 py-0' outline onClick={toggleBorrado}>
                  No
                </MDBBtn>
                <MDBBtn color='dark' className='button-primary px-3 py-0' outline onClick={handleDataDelete}>
                  Si
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal tabIndex='-1' show={modalVista} setShow={setModalVista}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle className='fw-bold'>Notificación</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleVista}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <h5 className="fw-bold mb-4">{formData.notificationTitle}</h5>
                <p>Mensaje:
                  &nbsp;
                  {
                    formData.notificationMessage
                  }
                </p>
                <MDBRow>
                  <MDBCol className='text-end'>
                    <i className='small'>
                      Objetivo: &nbsp;
                      {
                        notificationReferenceOptions.find((item) => item.value === formData.notificationReference).text
                      }
                    </i>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color='dark' className='button-secondary py-0 px-3' outline onClick={toggleVista}>
                  Ok
                </MDBBtn>
                {/*}
                <MDBBtn color='dark' className='button-primary py-2' size='lg' onClick={handleDataCreate}>
                  Enviar nuevamente
                </MDBBtn>
                {*/}
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    </MDBContainer>
  </div>
  )
}
export default NotificationManagerAdmin    