import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import RecoverPassword from './components/views/RecoverPassword';
import NewPassword from './components/views/NewPassword';
import Confirmation from './components/views/Confirmation';
import LoginAdmin from './components/views/LoginAdmin';
import HomeAdmin from './components/views/HomeAdmin';
import UserAdmin from './components/views/UserAdmin';
import ProfileAdmin from './components/views/ProfileAdmin';
import Version from './components/views/VersionAdmin';
import NotificationManagerAdmin from './components/views/NotificationManagerAdmin';
import NotificationAdmin from './components/views/NotificationAdmin';
import BraceletAdmin from './components/views/BraceletAdmin';
import ChallengeImporter from './components/views/ChallengeImporter';
import PrizeImporter from './components/views/PrizeImporter';
import VersionPrizeCodesAdmin from './components/views/VersionPrizeCodesAdmin';
import VersionChallengeCodesAdmin from './components/views/VersionChallengeCodesAdmin';

function App() {

  return (

    <BrowserRouter basename='/'>
      <Routes>
        <Route exact path='/' element={<LoginAdmin />} />
        <Route path='/login' element={<LoginAdmin />} />
        <Route path='/recover-password' element={<RecoverPassword />} />
        <Route path='/new-password/:id/:token' element={<NewPassword />} />
        <Route path='/confirmation' element={<Confirmation />} />
        <Route path='/home' element={<HomeAdmin />} />
        <Route path='/users' element={<UserAdmin />} />
        <Route path='/profile' element={<ProfileAdmin />} />
        <Route path='/versions' element={<Version />} />
        <Route path='/notification-management' element={<NotificationManagerAdmin />} />
        <Route path='/bracelets/:id' element={<BraceletAdmin />} />
        <Route path='/notifications' element={<NotificationAdmin />} />
        <Route path='/challenge-importer/:version_id' element={<ChallengeImporter />} />
        <Route path='/prize-importer/:version_id' element={<PrizeImporter />} />
        <Route path='/version-challenge-codes/:version_id' element={<VersionChallengeCodesAdmin />} />
        <Route path='/version-prize-codes/:version_id' element={<VersionPrizeCodesAdmin />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
