const getCities = async ()=>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/cities`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => console.log('error', error));
    return data
}
const showCity = async (city_id)=> {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      const data = await fetch(process.env.REACT_APP_API_BASEURL +`/cities/${city_id}` , requestOptions)
      .then(response => response.text())
      .then(result => {
        return result
      })
      .catch(error => console.log('error', error));
      return data 
}
const getCitiesByState = async (state_id)=> {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
    const data = await fetch(process.env.REACT_APP_API_BASEURL +`/cities/state/${state_id}` , requestOptions)
      .then(response => response.json())
      .then(result => {
        return result
      })
      .catch(error => console.log('error', error));
      return data 
}
export default {
    getCitiesByState,
    showCity,
    getCities
};